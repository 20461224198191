import './App.css';
import {GraphiQL} from "graphiql";
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import 'graphiql/graphiql.min.css';

function App() {
  let text = `# Welcome to the re:doubt GraphQL Explorer!
# To start using the GraphQL API, request an API token from @RedoubtAPIBot.
# Then, input the token in the Headers section at the bottom of this application
# using the following JSON:
# {
#  "X-API-Key": "%YOUR_TOKEN_HERE%"
# }
# Finally click "Re-fetch" button on the left side and explore the API!
`;
  return (
    <GraphiQL
      defaultQuery={text}
      fetcher={createGraphiQLFetcher({
        url: 'https://graphql.redoubt.online/v1/graphql',
      })}
    />
  );
}

export default App;
